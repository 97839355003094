import { io } from 'socket.io-client'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig().public

  const token = localStorage.getItem('authToken')

  const socketOrders = io(`${config.urlBaseEfici}orders`, {
    query: { token },
  }) // Reemplaza con tu URL de servidor Socket.IO

  return {
    provide: {
      socketOrders,
    },
  }
})
