import revive_payload_client_37BhGdIZcS from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.13.0_jiti@2.4.0__ioredis@5.4.1__o4vuiutjnegtgkck7hda4udgoi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vOfAhbJynZ from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.13.0_jiti@2.4.0__ioredis@5.4.1__o4vuiutjnegtgkck7hda4udgoi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ebZ28sqwxC from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.13.0_jiti@2.4.0__ioredis@5.4.1__o4vuiutjnegtgkck7hda4udgoi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_y3s3y6pWgP from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.13.0_jiti@2.4.0__ioredis@5.4.1__o4vuiutjnegtgkck7hda4udgoi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BVeTN1YBaC from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.13.0_jiti@2.4.0__ioredis@5.4.1__o4vuiutjnegtgkck7hda4udgoi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_0sjGLlHY4Z from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.13.0_jiti@2.4.0__ioredis@5.4.1__o4vuiutjnegtgkck7hda4udgoi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_YhM3oVWXbb from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.13.0_jiti@2.4.0__ioredis@5.4.1__o4vuiutjnegtgkck7hda4udgoi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_byGbDrcGVv from "/Users/runner/work/1/s/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/runner/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_geVY85kyte from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.13.0_jiti@2.4.0__ioredis@5.4.1__o4vuiutjnegtgkck7hda4udgoi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_zoF4yPIBkC from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt-socket-io@3.0.13/node_modules/nuxt-socket-io/lib/plugin.js";
import plugin_7wT7E0Nlls from "/Users/runner/work/1/s/node_modules/.pnpm/vue3-perfect-scrollbar@2.0.0_vue@3.5.12_typescript@5.6.3_/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_vOIvlsZNZd from "/Users/runner/work/1/s/node_modules/.pnpm/pinia-plugin-persistedstate@4.0.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.24.4_typescript@5_fbfh25pnqieyv5h6xfay5filpa/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_pb7rXuqWMF from "/Users/runner/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_TtkGIJTpyD from "/Users/runner/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_QZySvuisAR from "/Users/runner/work/1/s/node_modules/.pnpm/@nuxt+icon@1.6.1_magicast@0.3.5_rollup@4.24.4_vite@5.4.10_@types+node@22.9.0_terser@5.36.0__vue@3.5.12_typescript@5.6.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import axios_QMFgzss1s4 from "/Users/runner/work/1/s/plugins/axios.ts";
import global_variables_9gxCQlMwlK from "/Users/runner/work/1/s/plugins/global-variables.ts";
import maska_UHaKf2z1iQ from "/Users/runner/work/1/s/plugins/maska.ts";
import socket_io_client_oNh7qdoRBj from "/Users/runner/work/1/s/plugins/socket.io.client.ts";
import vue3_popper_YyL3RO5Qbz from "/Users/runner/work/1/s/plugins/vue3-popper.ts";
export default [
  revive_payload_client_37BhGdIZcS,
  unhead_vOfAhbJynZ,
  router_ebZ28sqwxC,
  payload_client_y3s3y6pWgP,
  navigation_repaint_client_BVeTN1YBaC,
  check_outdated_build_client_0sjGLlHY4Z,
  chunk_reload_client_YhM3oVWXbb,
  plugin_vue3_byGbDrcGVv,
  components_plugin_KR1HBZs4kY,
  prefetch_client_geVY85kyte,
  plugin_zoF4yPIBkC,
  plugin_7wT7E0Nlls,
  plugin_vOIvlsZNZd,
  switch_locale_path_ssr_pb7rXuqWMF,
  i18n_TtkGIJTpyD,
  plugin_QZySvuisAR,
  axios_QMFgzss1s4,
  global_variables_9gxCQlMwlK,
  maska_UHaKf2z1iQ,
  socket_io_client_oNh7qdoRBj,
  vue3_popper_YyL3RO5Qbz
]