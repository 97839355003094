import { default as accountsn78PeTjtYAMeta } from "/Users/runner/work/1/s/pages/accounts.vue?macro=true";
import { default as analyticsiYu8cwg40EMeta } from "/Users/runner/work/1/s/pages/analytics.vue?macro=true";
import { default as finance4TyYBV4Ov0Meta } from "/Users/runner/work/1/s/pages/finance.vue?macro=true";
import { default as indexf2FBEDimEZMeta } from "/Users/runner/work/1/s/pages/index.vue?macro=true";
import { default as loginamAtp1mYYrMeta } from "/Users/runner/work/1/s/pages/login.vue?macro=true";
import { default as apiPan0UBnTZSMeta } from "/Users/runner/work/1/s/pages/modules/[id]/api.vue?macro=true";
import { default as bannersAGWj2FCv8CMeta } from "/Users/runner/work/1/s/pages/modules/[id]/banners.vue?macro=true";
import { default as categoriesu1CBRKhMOnMeta } from "/Users/runner/work/1/s/pages/modules/[id]/categories.vue?macro=true";
import { default as edityIKW4RpN1gMeta } from "/Users/runner/work/1/s/pages/modules/[id]/edit.vue?macro=true";
import { default as indextbAbr1VzpGMeta } from "/Users/runner/work/1/s/pages/modules/[id]/index.vue?macro=true";
import { default as version8QpDLaYfcHMeta } from "/Users/runner/work/1/s/pages/modules/[id]/version.vue?macro=true";
import { default as indexSbJ03UzNhWMeta } from "/Users/runner/work/1/s/pages/modules/index.vue?macro=true";
import { default as ordersT6p2uMR96oMeta } from "/Users/runner/work/1/s/pages/orders.vue?macro=true";
import { default as indexDLwSewb5BmMeta } from "/Users/runner/work/1/s/pages/places/[id]/digital-menu/index.vue?macro=true";
import { default as indexozaFgwbhBdMeta } from "/Users/runner/work/1/s/pages/places/[id]/index.vue?macro=true";
import { default as index4JQqDXfr19Meta } from "/Users/runner/work/1/s/pages/places/[id]/invetory/index.vue?macro=true";
import { default as createMtnYtQ3YroMeta } from "/Users/runner/work/1/s/pages/places/[id]/invoice/create.vue?macro=true";
import { default as indexfgwn42K7QWMeta } from "/Users/runner/work/1/s/pages/places/[id]/invoice/index.vue?macro=true";
import { default as setuplvCSiqGbsFMeta } from "/Users/runner/work/1/s/pages/places/[id]/invoice/setup.vue?macro=true";
import { default as bannersy1KzPo2gQbMeta } from "/Users/runner/work/1/s/pages/places/[id]/marketplaces/[name]/banners.vue?macro=true";
import { default as indexWLXcnVsAMKMeta } from "/Users/runner/work/1/s/pages/places/[id]/marketplaces/[name]/index.vue?macro=true";
import { default as indexOhJaN7ATZZMeta } from "/Users/runner/work/1/s/pages/places/[id]/orders/index.vue?macro=true";
import { default as indexccSE6lToyXMeta } from "/Users/runner/work/1/s/pages/places/[id]/pos/index.vue?macro=true";
import { default as accountsBNQuE2jrphMeta } from "/Users/runner/work/1/s/pages/places/[id]/runner/accounts.vue?macro=true";
import { default as alliancesP47wPYa0sNMeta } from "/Users/runner/work/1/s/pages/places/[id]/runner/alliances.vue?macro=true";
import { default as indexO6lX57XUmaMeta } from "/Users/runner/work/1/s/pages/places/[id]/runner/index.vue?macro=true";
import { default as planF0StLvX8ZbMeta } from "/Users/runner/work/1/s/pages/places/[id]/runner/plan.vue?macro=true";
import { default as runnerslgWlzvNIDrMeta } from "/Users/runner/work/1/s/pages/places/[id]/runner/runners.vue?macro=true";
import { default as travelscll1miwxiOMeta } from "/Users/runner/work/1/s/pages/places/[id]/runner/travels.vue?macro=true";
import { default as accountskASjFDuIi2Meta } from "/Users/runner/work/1/s/pages/places/[id]/setup/accounts.vue?macro=true";
import { default as businessByzN6DGlCwMeta } from "/Users/runner/work/1/s/pages/places/[id]/setup/business.vue?macro=true";
import { default as couponsaxyfqSDL1yMeta } from "/Users/runner/work/1/s/pages/places/[id]/setup/coupons.vue?macro=true";
import { default as customers99kboBVIeRMeta } from "/Users/runner/work/1/s/pages/places/[id]/setup/customers.vue?macro=true";
import { default as domicileslp1tpv5oP0Meta } from "/Users/runner/work/1/s/pages/places/[id]/setup/domiciles.vue?macro=true";
import { default as indexpxBwp9G1dsMeta } from "/Users/runner/work/1/s/pages/places/[id]/setup/index.vue?macro=true";
import { default as modulesV4Z22vPPzaMeta } from "/Users/runner/work/1/s/pages/places/[id]/setup/modules.vue?macro=true";
import { default as paymentswzaUHBOJ8vMeta } from "/Users/runner/work/1/s/pages/places/[id]/setup/payments.vue?macro=true";
import { default as productsoFvv8jMJs0Meta } from "/Users/runner/work/1/s/pages/places/[id]/setup/products.vue?macro=true";
import { default as reportst0j52sQmhvMeta } from "/Users/runner/work/1/s/pages/places/[id]/setup/reports.vue?macro=true";
import { default as schedulesxLQp0xFSLnMeta } from "/Users/runner/work/1/s/pages/places/[id]/setup/schedules.vue?macro=true";
import { default as usersnEh3C3lbGNMeta } from "/Users/runner/work/1/s/pages/places/[id]/setup/users.vue?macro=true";
import { default as zoner6iDxIa8IKMeta } from "/Users/runner/work/1/s/pages/places/[id]/setup/zone.vue?macro=true";
import { default as indexnB2gptMRHBMeta } from "/Users/runner/work/1/s/pages/places/[id]/whole-sale/index.vue?macro=true";
import { default as indexRmc1gR8wVVMeta } from "/Users/runner/work/1/s/pages/places/index.vue?macro=true";
import { default as reportsle3hWF8JL6Meta } from "/Users/runner/work/1/s/pages/reports.vue?macro=true";
import { default as runnersx8LFJCdYAFMeta } from "/Users/runner/work/1/s/pages/runners.vue?macro=true";
import { default as citiesiaCu45IpPiMeta } from "/Users/runner/work/1/s/pages/setup/cities.vue?macro=true";
import { default as documentszFZee2lHlSMeta } from "/Users/runner/work/1/s/pages/setup/documents.vue?macro=true";
import { default as payments6EKZ2hR3EwMeta } from "/Users/runner/work/1/s/pages/setup/payments.vue?macro=true";
import { default as roles_45admin7FNDzfHP7lMeta } from "/Users/runner/work/1/s/pages/setup/roles-admin.vue?macro=true";
import { default as rolesUqN1wpRfqeMeta } from "/Users/runner/work/1/s/pages/setup/roles.vue?macro=true";
import { default as taxesa8BXfBDNNfMeta } from "/Users/runner/work/1/s/pages/setup/taxes.vue?macro=true";
import { default as typesgHl6Z1m2VuMeta } from "/Users/runner/work/1/s/pages/setup/types.vue?macro=true";
import { default as userstXnFf1QUUqMeta } from "/Users/runner/work/1/s/pages/setup/users.vue?macro=true";
export default [
  {
    name: "accounts",
    path: "/accounts",
    meta: accountsn78PeTjtYAMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/accounts.vue")
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/Users/runner/work/1/s/pages/analytics.vue")
  },
  {
    name: "finance",
    path: "/finance",
    component: () => import("/Users/runner/work/1/s/pages/finance.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexf2FBEDimEZMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/Users/runner/work/1/s/pages/login.vue")
  },
  {
    name: "modules-id-api",
    path: "/modules/:id()/api",
    component: () => import("/Users/runner/work/1/s/pages/modules/[id]/api.vue")
  },
  {
    name: "modules-id-banners",
    path: "/modules/:id()/banners",
    meta: bannersAGWj2FCv8CMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/modules/[id]/banners.vue")
  },
  {
    name: "modules-id-categories",
    path: "/modules/:id()/categories",
    meta: categoriesu1CBRKhMOnMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/modules/[id]/categories.vue")
  },
  {
    name: "modules-id-edit",
    path: "/modules/:id()/edit",
    meta: edityIKW4RpN1gMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/modules/[id]/edit.vue")
  },
  {
    name: "modules-id",
    path: "/modules/:id()",
    meta: indextbAbr1VzpGMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/modules/[id]/index.vue")
  },
  {
    name: "modules-id-version",
    path: "/modules/:id()/version",
    meta: version8QpDLaYfcHMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/modules/[id]/version.vue")
  },
  {
    name: "modules",
    path: "/modules",
    meta: indexSbJ03UzNhWMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/modules/index.vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: ordersT6p2uMR96oMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/orders.vue")
  },
  {
    name: "places-id-digital-menu",
    path: "/places/:id()/digital-menu",
    meta: indexDLwSewb5BmMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/digital-menu/index.vue")
  },
  {
    name: "places-id",
    path: "/places/:id()",
    meta: indexozaFgwbhBdMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/index.vue")
  },
  {
    name: "places-id-invetory",
    path: "/places/:id()/invetory",
    meta: index4JQqDXfr19Meta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/invetory/index.vue")
  },
  {
    name: "places-id-invoice-create",
    path: "/places/:id()/invoice/create",
    meta: createMtnYtQ3YroMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/invoice/create.vue")
  },
  {
    name: "places-id-invoice",
    path: "/places/:id()/invoice",
    meta: indexfgwn42K7QWMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/invoice/index.vue")
  },
  {
    name: "places-id-invoice-setup",
    path: "/places/:id()/invoice/setup",
    meta: setuplvCSiqGbsFMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/invoice/setup.vue")
  },
  {
    name: "places-id-marketplaces-name-banners",
    path: "/places/:id()/marketplaces/:name()/banners",
    meta: bannersy1KzPo2gQbMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/marketplaces/[name]/banners.vue")
  },
  {
    name: "places-id-marketplaces-name",
    path: "/places/:id()/marketplaces/:name()",
    meta: indexWLXcnVsAMKMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/marketplaces/[name]/index.vue")
  },
  {
    name: "places-id-orders",
    path: "/places/:id()/orders",
    meta: indexOhJaN7ATZZMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/orders/index.vue")
  },
  {
    name: "places-id-pos",
    path: "/places/:id()/pos",
    meta: indexccSE6lToyXMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/pos/index.vue")
  },
  {
    name: "places-id-runner-accounts",
    path: "/places/:id()/runner/accounts",
    meta: accountsBNQuE2jrphMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/runner/accounts.vue")
  },
  {
    name: "places-id-runner-alliances",
    path: "/places/:id()/runner/alliances",
    meta: alliancesP47wPYa0sNMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/runner/alliances.vue")
  },
  {
    name: "places-id-runner",
    path: "/places/:id()/runner",
    meta: indexO6lX57XUmaMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/runner/index.vue")
  },
  {
    name: "places-id-runner-plan",
    path: "/places/:id()/runner/plan",
    meta: planF0StLvX8ZbMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/runner/plan.vue")
  },
  {
    name: "places-id-runner-runners",
    path: "/places/:id()/runner/runners",
    meta: runnerslgWlzvNIDrMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/runner/runners.vue")
  },
  {
    name: "places-id-runner-travels",
    path: "/places/:id()/runner/travels",
    meta: travelscll1miwxiOMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/runner/travels.vue")
  },
  {
    name: "places-id-setup-accounts",
    path: "/places/:id()/setup/accounts",
    meta: accountskASjFDuIi2Meta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/accounts.vue")
  },
  {
    name: "places-id-setup-business",
    path: "/places/:id()/setup/business",
    meta: businessByzN6DGlCwMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/business.vue")
  },
  {
    name: "places-id-setup-coupons",
    path: "/places/:id()/setup/coupons",
    meta: couponsaxyfqSDL1yMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/coupons.vue")
  },
  {
    name: "places-id-setup-customers",
    path: "/places/:id()/setup/customers",
    meta: customers99kboBVIeRMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/customers.vue")
  },
  {
    name: "places-id-setup-domiciles",
    path: "/places/:id()/setup/domiciles",
    meta: domicileslp1tpv5oP0Meta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/domiciles.vue")
  },
  {
    name: "places-id-setup",
    path: "/places/:id()/setup",
    meta: indexpxBwp9G1dsMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/index.vue")
  },
  {
    name: "places-id-setup-modules",
    path: "/places/:id()/setup/modules",
    meta: modulesV4Z22vPPzaMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/modules.vue")
  },
  {
    name: "places-id-setup-payments",
    path: "/places/:id()/setup/payments",
    meta: paymentswzaUHBOJ8vMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/payments.vue")
  },
  {
    name: "places-id-setup-products",
    path: "/places/:id()/setup/products",
    meta: productsoFvv8jMJs0Meta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/products.vue")
  },
  {
    name: "places-id-setup-reports",
    path: "/places/:id()/setup/reports",
    meta: reportst0j52sQmhvMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/reports.vue")
  },
  {
    name: "places-id-setup-schedules",
    path: "/places/:id()/setup/schedules",
    meta: schedulesxLQp0xFSLnMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/schedules.vue")
  },
  {
    name: "places-id-setup-users",
    path: "/places/:id()/setup/users",
    meta: usersnEh3C3lbGNMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/users.vue")
  },
  {
    name: "places-id-setup-zone",
    path: "/places/:id()/setup/zone",
    meta: zoner6iDxIa8IKMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/setup/zone.vue")
  },
  {
    name: "places-id-whole-sale",
    path: "/places/:id()/whole-sale",
    meta: indexnB2gptMRHBMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/[id]/whole-sale/index.vue")
  },
  {
    name: "places",
    path: "/places",
    meta: indexRmc1gR8wVVMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/places/index.vue")
  },
  {
    name: "reports",
    path: "/reports",
    meta: reportsle3hWF8JL6Meta || {},
    component: () => import("/Users/runner/work/1/s/pages/reports.vue")
  },
  {
    name: "runners",
    path: "/runners",
    meta: runnersx8LFJCdYAFMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/runners.vue")
  },
  {
    name: "setup-cities",
    path: "/setup/cities",
    meta: citiesiaCu45IpPiMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/setup/cities.vue")
  },
  {
    name: "setup-documents",
    path: "/setup/documents",
    meta: documentszFZee2lHlSMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/setup/documents.vue")
  },
  {
    name: "setup-payments",
    path: "/setup/payments",
    meta: payments6EKZ2hR3EwMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/setup/payments.vue")
  },
  {
    name: "setup-roles-admin",
    path: "/setup/roles-admin",
    meta: roles_45admin7FNDzfHP7lMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/setup/roles-admin.vue")
  },
  {
    name: "setup-roles",
    path: "/setup/roles",
    meta: rolesUqN1wpRfqeMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/setup/roles.vue")
  },
  {
    name: "setup-taxes",
    path: "/setup/taxes",
    meta: taxesa8BXfBDNNfMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/setup/taxes.vue")
  },
  {
    name: "setup-types",
    path: "/setup/types",
    meta: typesgHl6Z1m2VuMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/setup/types.vue")
  },
  {
    name: "setup-users",
    path: "/setup/users",
    meta: userstXnFf1QUUqMeta || {},
    component: () => import("/Users/runner/work/1/s/pages/setup/users.vue")
  }
]