export default {
  auth: () => import("/Users/runner/work/1/s/layouts/auth.vue"),
  default: () => import("/Users/runner/work/1/s/layouts/default.vue"),
  "places-menu": () => import("/Users/runner/work/1/s/layouts/places-menu.vue"),
  pos: () => import("/Users/runner/work/1/s/layouts/pos.vue"),
  "sidebar-invoice-menu": () => import("/Users/runner/work/1/s/layouts/sidebar-invoice-menu.vue"),
  "sidebar-marketplace-menu": () => import("/Users/runner/work/1/s/layouts/sidebar-marketplace-menu.vue"),
  "sidebar-module-menu": () => import("/Users/runner/work/1/s/layouts/sidebar-module-menu.vue"),
  "sidebar-place-menu": () => import("/Users/runner/work/1/s/layouts/sidebar-place-menu.vue"),
  "sidebar-runner-menu": () => import("/Users/runner/work/1/s/layouts/sidebar-runner-menu.vue")
}