import type { Code, SetLocale } from './types/locale.js'
import { useAppStore } from '@/stores/index.js'
import { $themeConfig } from './theme.config.js'

export default {
  init(setLocale: SetLocale) {
    const store = useAppStore()

    // set default styles
    let val = localStorage.getItem('theme') // light, dark, system
    val = val || $themeConfig.theme
    store.toggleTheme(val)

    val = localStorage.getItem('menu') // vertical, collapsible-vertical, horizontal
    val = val || $themeConfig.menu
    store.toggleMenu(val)

    val = localStorage.getItem('layout') // full, boxed-layout
    val = val || $themeConfig.layout
    store.toggleLayout(val)

    val = localStorage.getItem('i18n_locale') // en, da, de, el, es, fr, hu, it, ja, pl, pt, ru, sv, tr, zh

    val = val || $themeConfig.locale

    const list = store.languageList
    const item = list.find(item => item.code === val)
    if (item) {
      this.toggleLanguage(item, setLocale)
    }

    val = localStorage.getItem('rtlClass') // rtl, ltr
    val = val || $themeConfig.rtlClass
    store.toggleRTL(val)

    val = localStorage.getItem('animation') // animate__fadeIn, animate__fadeInDown, animate__fadeInUp, animate__fadeInLeft, animate__fadeInRight, animate__slideInDown, animate__slideInLeft, animate__slideInRight, animate__zoomIn
    val = val || $themeConfig.animation
    store.toggleAnimation(val)

    val = localStorage.getItem('navbar') // navbar-sticky, navbar-floating, navbar-static
    val = val || $themeConfig.navbar
    store.toggleNavbar(val)

    val = localStorage.getItem('semidark')

    // @ts-expect-error
    val = val === 'true' ? true : $themeConfig.semidark

    store.toggleSemidark(val as unknown as boolean)
  },

  toggleLanguage(item: Code, setLocale: SetLocale) {
    const store = useAppStore()

    let lang: Code | null = null
    if (item) {
      lang = item
    }
    else {
      let code = store.locale || null
      if (!code) {
        code = localStorage.getItem('i18n_locale')
      }

      item = store.languageList.find(d => d.code === code)!
      if (item) {
        lang = item
      }
    }

    if (!lang) {
      lang = store.languageList.find(d => d.code === 'en')!
    }

    store.toggleLocale(lang!.code, setLocale)
    return lang
  },

  changeAnimation(type = 'add') {
    const store = useAppStore()
    if (store.animation) {
      const eleanimation = document.querySelector('.animation')
      if (type === 'add') {
        eleanimation?.classList.add('animate__animated')
        eleanimation?.classList.add(store.animation)
      }
      else {
        eleanimation?.classList.remove('animate__animated')
        eleanimation?.classList.remove(store.animation)
      }
    }
  },
}
