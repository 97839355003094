import type { Code, SetLocale } from '@/types/locale.js'
import appSetting from '@/app-setting.js'
import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    isDarkMode: false,
    mainLayout: 'app',
    theme: 'light',
    menu: 'vertical',
    layout: 'full',
    rtlClass: 'ltr',
    animation: '',
    navbar: 'navbar-sticky',
    locale: 'es',
    sidebar: false,
    languageList: [
      { code: 'es', flag: 'CO', name: 'Spanish' },
      { code: 'en', flag: 'US', name: 'English' },
    ] as Code[],
    isShowMainLoader: true,
    semidark: false,
  }),

  actions: {
    setMainLayout(payload: string = '') {
      this.mainLayout = payload // app , auth
    },
    toggleTheme(payload: string = '') {
      payload = payload || this.theme // light|dark|system
      localStorage.setItem('theme', payload)
      this.theme = payload
      if (payload === 'light') {
        this.isDarkMode = false
      }
      else if (payload === 'dark') {
        this.isDarkMode = true
      }
      else if (payload === 'system') {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          this.isDarkMode = true
        }
        else {
          this.isDarkMode = false
        }
      }

      if (this.isDarkMode) {
        document.querySelector('body')?.classList.add('dark')
      }
      else {
        document.querySelector('body')?.classList.remove('dark')
      }
    },
    toggleMenu(payload: string = '') {
      payload = payload || this.menu // vertical, collapsible-vertical, horizontal
      this.sidebar = false // reset sidebar state
      localStorage.setItem('menu', payload)
      this.menu = payload
    },
    toggleLayout(payload: string = '') {
      payload = payload || this.layout // full, boxed-layout
      localStorage.setItem('layout', payload)
      this.layout = payload
    },
    toggleRTL(payload: string = '') {
      payload = payload || this.rtlClass // rtl, ltr
      localStorage.setItem('rtlClass', payload)
      this.rtlClass = payload
      document.querySelector('html')?.setAttribute('dir', this.rtlClass || 'ltr')
    },
    toggleAnimation(payload: string = '') {
      payload = payload || this.animation // animate__fadeIn, animate__fadeInDown, animate__fadeInUp, animate__fadeInLeft, animate__fadeInRight, animate__slideInDown, animate__slideInLeft, animate__slideInRight, animate__zoomIn
      payload = payload?.trim()
      localStorage.setItem('animation', payload)
      this.animation = payload
      appSetting.changeAnimation()
    },
    toggleNavbar(payload: string = '') {
      payload = payload || this.navbar // navbar-sticky, navbar-floating, navbar-static
      localStorage.setItem('navbar', payload)
      this.navbar = payload
    },
    toggleSemidark(payload: boolean | null = null) {
      payload = payload || false
      localStorage.setItem('semidark', payload as unknown as string)
      this.semidark = payload as boolean
    },
    toggleLocale(payload: string = '', setLocale: SetLocale) {
      payload = payload || this.locale
      localStorage.setItem('i18n_locale', payload)
      this.locale = payload
      setLocale(payload)
      if (this.locale?.toLowerCase() === 'ae') {
        this.toggleRTL('rtl')
      }
      else {
        this.toggleRTL('ltr')
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar
    },
    toggleMainLoader() {
      this.isShowMainLoader = true
      setTimeout(() => {
        this.isShowMainLoader = false
      }, 500)
    },
    toggleMainLoaderManual(active: boolean) {
      this.isShowMainLoader = active
    },
  },
  getters: {},
})
