import axios from 'axios'

export default defineNuxtPlugin(() => {
  const store = useAppStore()

  const config = useRuntimeConfig().public
  const urlEfici = axios.create({
    baseURL: `${config.urlBaseEfici}api/v1.0/`,
  })

  const urlRunner = axios.create({
    baseURL: `${config.urlBaseRunner}api/v1.0/`,
  })

  const urlDomiplace = axios.create({
    baseURL: `${config.urlBaseDomiplace}api/v1.0/`,
  })

  const urlModash = axios.create({
    baseURL: `${config.urlBaseModash}api/v1.0/`,
  })

  const urlBuyloo = axios.create({
    baseURL: `${config.urlBaseBuyloo}api/v1.0/`,
  })

  urlEfici.defaults.headers.common['Content-Type'] = 'application/json'
  urlRunner.defaults.headers.common['Content-Type'] = 'application/json'
  urlDomiplace.defaults.headers.common['Content-Type'] = 'application/json'

  urlEfici.interceptors.response.use(
    (response) => {
      if (response.config?.loader) {
        store.toggleMainLoaderManual(false)
      }
      return response
    },
    (error) => {
      if (error.config?.loader) {
        store.toggleMainLoaderManual(false)
      }
      return Promise.reject(error)
    },
  )

  urlEfici.interceptors.request.use(
    (request) => {
      if (request.loader) {
        store.toggleMainLoaderManual(true)
      }
      if (typeof window !== 'undefined') {
        // Solo acceder a localStorage en el lado del cliente
        const token = localStorage.getItem('authToken')
        if (token) {
          request.headers.Authorization = `Bearer ${token}`
        }
      }
      return request
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  urlDomiplace.interceptors.response.use(
    (response) => {
      if (response.config.loader) {
        store.toggleMainLoaderManual(false)
      }
      return response
    },
    (error) => {
      if (error.config?.loader) {
        store.toggleMainLoaderManual(false)
      }
      return Promise.reject(error)
    },
  )
  urlDomiplace.interceptors.request.use(
    (request) => {
      if (request.loader) {
        store.toggleMainLoaderManual(true)
      }
      if (typeof window !== 'undefined') {
        // Solo acceder a localStorage en el lado del cliente
        const token = localStorage.getItem('authToken')
        if (token) {
          request.headers.Authorization = `Bearer ${token}`
        }
      }
      return request
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  urlRunner.interceptors.response.use(
    (response) => {
      if (response.config.loader) {
        store.toggleMainLoaderManual(false)
      }
      return response
    },
    (error) => {
      if (error.config?.loader) {
        store.toggleMainLoaderManual(false)
      }
      return Promise.reject(error)
    },
  )
  urlRunner.interceptors.request.use(
    (request) => {
      if (request.loader) {
        store.toggleMainLoaderManual(true)
      }
      if (typeof window !== 'undefined') {
        // Solo acceder a localStorage en el lado del cliente
        const token = localStorage.getItem('authToken')
        if (token) {
          request.headers.Authorization = `Bearer ${token}`
        }
      }
      return request
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  return {
    provide: {
      urlEfici,
      urlRunner,
      urlDomiplace,
      urlModash,
      urlBuyloo,
    },
  }
})
